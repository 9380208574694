<template>
  <div>
    <h4 class="workplatform-title m-b-10">工单任务状态分布</h4>
    <Table
      stripe
      size="small"
      :data="taskStatusList"
      :columns="tableColumns"
    ></Table>

    <h4 class="workplatform-title m-t-50 m-b-10">工单任务类型分布</h4>
    <Table
      stripe
      size="small"
      :data="taskTypeList"
      :columns="tableColumns"
    ></Table>

    <h4 class="workplatform-title m-t-50 m-b-10">工单任务费用汇总</h4>
    <Row class="table-title">
      <i-col
        span="12"
        style="padding:5px 0;"
      >
        <div class="ivu-table-cell">类型</div>
      </i-col>
      <i-col
        span="12"
        style="padding:5px 0;"
      >
        <div class="ivu-table-cell text-right">金额</div>
      </i-col>
    </Row>
    <Row class="table-row-2">
      <i-col
        span="12"
        style="padding:6px 0;"
      >
        <div class="ivu-table-cell">
          已质检验收
        </div>
      </i-col>
      <i-col
        span="12"
        style="padding:6px 0;"
      >
        <div class="ivu-table-cell text-right money">
          {{fomartMoney(taskAmountStatData.checkAmount)}}
        </div>
      </i-col>
    </Row>
    <Row class="table-row-1">
      <i-col
        span="12"
        style="padding:6px 0;"
      >
        <div class="ivu-table-cell">
          未质检验收
        </div>
      </i-col>
      <i-col
        span="12"
        style="padding:6px 0;"
      >
        <div class="ivu-table-cell text-right money">
          {{fomartMoney(taskAmountStatData.notCheckAmount)}}
        </div>
      </i-col>
    </Row>

  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
// import { getPlanTaskStatusStatistic, getPlanTaskTypeStatistic, getAmountStat } from '@/api/msp/plan'

export default {
  data () {
    return {
      // taskStatusList: [],
      // taskTypeList: [],
      tableColumns: [
        { title: '类型', key: 'signName' },
        { title: '数量', align: 'right', key: 'quantity' }
      ]
      // taskAmountStatData: {}
    }
  },
  computed: {
    taskStatusList () {
      return this.$store.state.qualityTesting.taskStatusList
    },
    taskTypeList () {
      return this.$store.state.qualityTesting.taskTypeList
    },
    taskAmountStatData () {
      return this.$store.state.qualityTesting.taskAmountStatData
    }
  },
  methods: {
    // loadPlanStatistic (planItem) {
    //   if (planItem && planItem.domainId) {
    //     const query = { actionDate: planItem.actionDate, domainId: planItem.domainId }

    //     getPlanTaskStatusStatistic(query).then(res => {
    //       this.taskStatusList = res
    //     })

    //     getPlanTaskTypeStatistic(query).then(res => {
    //       this.taskTypeList = res
    //     })

    //     getAmountStat(query).then(res => {
    //       if (res && !res.errcode) {
    //         this.taskAmountStatData = res
    //       }
    //     })
    //   } else {
    //     this.taskStatusList = []
    //     this.taskTypeList = []
    //     this.taskAmountStatData = {}
    //   }
    // },
    fomartMoney (number) {
      return toMoney(number)
    }
  }
}
</script>
